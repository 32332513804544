<template>
  <div class="layout">
    <div class="header">
      <div class="header_top fsb">
        <router-link to="/" class="routerLink">
          <div class="fac header_left">
            <img src="../../assets/img/creatorPlatform/logo.png" />
            <div>
              <h2>百万麒麟</h2>
              <span>最好的分享就是知识共享</span>
            </div>
          </div>
        </router-link>
        <div class="fac header_right">
          <div class="fac icon" @click="$router.push('/news')">
            <img src="../../assets/img/creatorPlatform/icon1.png" />
            <span>消息</span>
          </div>
          <div class="fac icon" @click="logOut">
            <img src="../../assets/img/creatorPlatform/icon2.png" />
            <span>退出登录</span>
          </div>
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="conter_left">
        <div class="conter_left_top">
          <img :src="userData.userImg" />
          <span>{{ userData.userName }}</span>
        </div>
        <div class="fsb conter_left_middle">
          <div>
            <span>{{ three.fans }}</span>
            <span>粉丝</span>
          </div>
          <div>
            <span>{{ three.attention }}</span>
            <span>关注</span>
          </div>
          <div>
            <span>{{ three.friend }}</span>
            <span>好友</span>
          </div>
        </div>
        <div class="conter_left_botton">
          <div v-for="(item, index) in leftArr" :key="index" :class="{ active: leftActive == item.name }" @click="conterLeftBotton(item.name)">
            <img :src="require('../../assets/img/creatorPlatform/' + item.img + '.png')" />
            <span>{{ item.text }}</span>
          </div>
        </div>
      </div>
      <div class="conter_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { getThreeNum } from '@/utils/serveApi';
export default {
  name: 'creatorPlatform',
  data() {
    return {
      leftActive: this.$route.name,
      userData: this.$store.getters.userData,
      three: {
        friend: 0,
        fans: 0,
        attention: 0
      },
      leftArr: [
        {
          img: 'upload_admin',
          name: 'uploadVideo',
          text: '上传视频'
        },
        {
          img: 'video_admin',
          name: 'adminVideo',
          text: '视频管理'
        },
        {
          img: 'collection_admin',
          name: 'collectionAdmin',
          text: '合集管理'
        }
      ]
    };
  },
  created() {
    getThreeNum().then(res => {
      if (res && res.stateCode == 1){
        this.three = res.data;
      } 
    });
  },
  methods: {
    conterLeftBotton(name) {
      this.leftActive = name;
      this.$router.push('/' + name);
    },
    logOut() {
      this.$router.push('/');
      localStorage.removeItem('userData');
    }
  }
};
</script>
<style lang="scss" scoped>
.layout {
  color: #000000;
  min-height: 100vh;
  background-color: #eef2f5;
  .header {
    background-color: #ffffff;
    height: 106px;
    .routerLink {
      text-decoration: none;
    }
    &_top {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
    }
    &_left {
      img {
        width: 52px;
        height: 52px;
        margin-right: 8px;
      }
      h2 {
        color: #000000;
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
      }
      span {
        color: #222222;
        font-size: 14px;
      }
    }
    &_right {
      font-size: 18px;
      color: #c1c1c1;
      .icon {
        padding-left: 34px;
        cursor: pointer;
        span {
          margin-left: 8px;
        }
      }
    }
  }
  .conter {
    width: 1200px;
    margin: auto;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    &_left {
      width: 282px;
      background-color: #ffffff;
      border-radius: 10px;
      margin-right: 24px;
      &_top {
        padding-top: 80px;
        text-align: center;
        img {
          width: 120px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 12px;
        }
        span {
          font-size: 22px;
          color: #000000;
        }
      }
      &_middle {
        padding: 40px;
        font-size: 20px;
        margin: 0 6px;
        span {
          text-align: center;
          display: block;
        }
        & > div > span:first-child {
          font-weight: bold;
        }
        border-bottom: 1px solid #eef2f5;
      }
      &_botton {
        padding: 24px 0;
        & > div {
          display: flex;
          align-items: center;
          height: 68px;
          padding-left: 40px;
          cursor: pointer;
          & > img {
            width: 24px;
            padding-right: 11px;
          }
        }
        & > div.active {
          color: #ffb600;
          background-color: #fff8e5;
        }
      }
    }
    &_right {
      flex: 1;
      background-color: #ffffff;
      border-radius: 10px;
    }
  }
}
</style>
